import styled from "@emotion/styled"

const Texto = styled.div`
    color: #FFF;
    font-size: 15px;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    margin-top: 100px;
    text-align: center;
` 

const Footer = () => {
  return (
    <Texto>
        <p>By Juan Javier Romero Resek &copy; 2021</p>
    </Texto>
  )
}

export default Footer