const monedas = [
    {id: 'USD', nombre:'Dolar de Estados Unidos (USD)'},
    {id: 'ARS', nombre:'Peso Argentino (ARS)'},
    {id: 'PYG', nombre:'Guarani Paraguayo (PYG)'},
    {id: 'BRL', nombre:'Real Brasilero (BRL)'},
]

export {
    monedas
}

