import styled from "@emotion/styled"

const Contenedor = styled.div`
  color: #FFF;
  font-family: 'Lato', sans-serif;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 30px;

`

const Imagen = styled.img`
  display: block;
  width:120px;
`


const Texto = styled.div`
  font-size: 18px;
  span {
    font-weight: 700;
  }

`

const Precio = styled.div`
  font-size: 20px;
  span {
    font-weight: 700;
  }

`

const Resultado = ({resultado}) => {
  const {PRICE, HIGHDAY, LOWDAY, CHANGEPCT24HOUR, IMAGEURL,LASTUPDATE} = resultado
  return (
    <Contenedor>
        <Imagen 
          src={`https://cryptocompare.com/${IMAGEURL}`} 
          alt='Imagen Cripto' 
         
        />
        <div>
          <Precio>Precio: <span>{PRICE}</span></Precio>
          <Texto>24h máximo: <span>{HIGHDAY}</span></Texto>
          <Texto>24h mínimo: <span>{LOWDAY}</span></Texto>
          <Texto>24h cambio: <span>{CHANGEPCT24HOUR}%</span></Texto>
          <Texto>Actualización: <span>{LASTUPDATE}</span></Texto>
        </div>
    </Contenedor>
  )
}

export default Resultado